<template>
	<div>
		<div v-for="(item,index) in schema" :key="item.id">
			<item-renderer
					ref="item"
					:index="index"
					:item="item"
					:styles="styles"
					:disabled="false"
					:results="results"
					:account-logo="accountLogo"
					:active="false"
					@input="handleInput($event)"
			></item-renderer>
		</div>
	</div>
</template>

<script>
import ItemRenderer from "@/modules/forms/v2/schema/ItemRenderer";
import RendererMixin from "@/modules/forms/v2/schema/RendererMixin";

export default {
	name: "MeetingFormV2",

	props: ['template','meet'],

	mixins: [RendererMixin],

	components: {ItemRenderer},

	data: function () {
		return {
			results: [],
		}
	},

	mounted() {
	},

	beforeDestroy() {
	},

	methods: {
		validate: function () {
			let invalidCount = 0;
			for(let i=0; i < this.schema.length; i++){
				if(!this.$refs.item[i].validate()){
					invalidCount++
				}
			}
			if(invalidCount){
				return false;
			}else{
				return true;
			}
		},

		handleInput: function(event){
			let ix = this.results.findIndex(r => r.item.id === event.item.id);
			if(ix > -1){
				this.results.splice(ix,1,event);
			}else{
				this.results.push(event);
			}
		},

		setFormResult: function (formResult) {
			this.results.sort((a,b) => a.index.toString().localeCompare(b.index.toString()));

			formResult.answers = [];
			formResult.files = [];

			for(let i=0; i < this.results.length; i++){
				let item = this.results[i].item;
				let value = this.results[i].value;
				let container = this.results[i].container;

				console.log('******************************************');
				console.log(item.fieldName);

				if(container && !this.colConditionMet(container)){
					console.log('exiting because container is hidden');
					continue
				}else if(!this.colConditionMet(item)) {
					console.log('exiting because item is hidden');
					continue
				}

				if(item.schemaMapping){
					formResult[item.schemaMapping] = Array.isArray(value) ? value.join(', ') : value;
					//continue;
				}

				let answer;
				if (item.type === 'FileInput') {
					formResult.files.push(...value);
					answer = JSON.stringify(value.map((f) => f.name));
				}else{
					answer = Array.isArray(value) ? value.join(', ') : value;
				}

				formResult.answers.push({
					id: item.id,
					fieldKey: item.fieldName,
					fieldType: item.type,
					question: item.question,
					answer: answer
				});
			}

			return formResult;
		}
	},

	computed: {
		styles: function () {
			return this.template.formSettingsV2;
		},

		schema: function(){
			return this.template.schemaV2.filter(s => s.type !== 'NewPage');
		},

		accountLogo: function () {
			if (this.$vuetify.theme.dark && this.meet.account.accountLogoDark) {
				return this.meet.account.accountLogoDark;
			} else {
				return this.meet.account.accountLogo;
			}
		},

		justification: function() {
			if (this.styles.submitAlignment === 'JUSTIFIED') {
				return 'space-between';
			} else if (this.styles.submitAlignment === 'LEFT') {
				return 'flex-start';
			} else if (this.styles.submitAlignment === 'RIGHT') {
				return 'flex-end';
			} else {
				return 'center';
			}
		},
	},

}
</script>

<style scoped lang="scss">

</style>